<template>
  <v-card class="mx-auto" max-width="600" flat>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" persistent max-width="600">
        <v-card width="600">
          <v-card-title class="text-h5">
            {{ editedItem.id > 0 ? "" : "New " }} Evaluation
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="editedItem.eval_id"
                  label="Decision"
                  :rules="[(v) => !!v || 'Decision required']"
                  :items="eval_list"
                  :item-value="'id'"
                  :item-text="'label'"
                  @input="changes++"
                  dense
                >
                </v-select>
              </v-col>
              <!-- <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="editedItem.eval_by"
                  label="Evaluated By"
                  :rules="[(v) => !!v || 'Evaluation By required']"
                  :items="users_eval"
                  :item-value="'id'"
                  :item-text="'name'"
                  dense
                >
                </v-select>
              </v-col> -->
              <v-col cols="12" sm="4" md="4">
                <datepicker
                  label="Evaluation Date"
                  :edit="true"
                  v-model="editedItem.eval_date"
                  :date_min="date_min"
                  :date_max="today"
                  :rules="[(v) => !!v || 'Evaluation Date required']"
                  :clearable="false"
                  @input="changes++"
                  :key="cs"
                ></datepicker>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedItem.comment"
                  :rules="[(v) => !!v || 'Comment required']"
                  @input="changes++"
                  label="Comment"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="progress"
              color="primary"
              @click="validate"
              v-if="editedItem.statut_id == 0 && editedItem.id > 0"
            >
              validate
            </v-btn>
            <v-btn
              :loading="progress"
              color="primary"
              @click="save"
              :disabled="changes == 0"
            >
              Save
            </v-btn>
            <v-btn color="error" @click="close"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-card>
</template>
<script>
import CREATE_EVAL from "../graphql/Well/CREATE_EVAL.gql";
import UPDATE_EVAL from "../graphql/Well/UPDATE_EVAL.gql";
import { dateam } from "@/utils/functions.js";
export default {
  components: {
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    showForm: Boolean,
    well: Object,
    eval: Object,
    list: Array,
    evaluation_list: Array,
    user_list: Array,
  },
  data() {
    return {
      comment_type: "1",
      comment: null,
      valid: true,
      progress: false,
      cs: 150,
      editedItem: {},
      test_readonly: false,
      changes: 0,
    };
  },
  computed: {
    date_min() {
      let d = this.well.drillingdate;
      if (this.list)
        if (this.list)
          if (this.list.length > 1) {
            const todayDate = new Date(this.list[1].eval_date);
            todayDate.setDate(todayDate.getDate() + 1);
            d = todayDate.toISOString().slice(0, 10);
          }

      return d;
    },
    dateam() {
      return dateam;
    },
    eval_list() {
      let l = [];
      if (this.evaluation_list)
        l = this.evaluation_list.filter((elm) => elm.id != 0);
      return l;
    },
    users_eval() {
      let l = [];
      if (this.user_list)
        l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
      return l;
    },
    today() {
      return this.$store.state.today;
    },
  },
  created() {
    if (this.eval) {
      if (this.eval.id > 0) {
        this.editedItem = Object.assign({}, this.eval);
        this.cs++;
      } else
        this.editedItem = Object.assign(
          {},
          { id: -1, eval_id: null, statut_id: 0 }
        );
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async validate() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Are you sûr to validate this evaluation?",
          { color: "orange darken-3" }
        )
      ) {
        let v = {
          wellseval: {
            id: this.editedItem.id,
            statut_id: 1,
            comment: this.editedItem.comment,
            eval_by: this.editedItem.eval_by,
            eval_date: this.editedItem.eval_date,
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let r = await this.$maj(UPDATE_EVAL, v);
        if (r.ok) {
          this.editedItem.statut_id = 1;
          this.list.splice(this.editedItem.index, 1, this.editedItem);
          this.$emit("eval_validate", this.editedItem);
        }
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        let i = this.evaluation_list.findIndex(
          (elm) => elm.id == this.editedItem.eval_id
        );
        if (i >= 0) {
          this.editedItem.color = this.evaluation_list[i].color;
          this.editedItem.name = this.evaluation_list[i].label;
        }
        this.editedItem.eval_by = this.$store.state.me.id;
        i = this.user_list.findIndex(
          (elm) => elm.id == this.editedItem.eval_by
        );
        if (i >= 0) {
          this.editedItem.eval_name = this.user_list[i].name;
        }
        i = this.evaluation_list.findIndex(
          (elm) => elm.id == this.editedItem.eval_id
        );
        if (i >= 0) {
          this.editedItem.status_name = this.evaluation_list[i].label;
        }
        if (this.editedItem.id > 0) {
          let v = {
            wellseval: {
              id: this.editedItem.id,
              eval_id: this.editedItem.eval_id,
              comment: this.editedItem.comment,
              eval_by: this.editedItem.eval_by,
              eval_date: this.editedItem.eval_date,
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.$maj(UPDATE_EVAL, v);
          if (r.ok) {
            this.list.splice(this.editedItem.index, 1, this.editedItem);
          }
        } else {
          let v = {
            wellseval: {
              well_id: this.well.id,
              eval_id: this.editedItem.eval_id,
              comment: this.editedItem.comment,
              eval_by: this.editedItem.eval_by,
              eval_date: this.editedItem.eval_date,
              create_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.$maj(CREATE_EVAL, v);
          if (r.ok) {
            this.editedItem.id = r.data.createWellsEval.id;
            this.list.unshift(this.editedItem);
          }
        }
        this.changes = 0;
      }
    },
  },
};
</script>
